<template>
    <div class="popup_sub_container">
        <div class="for_inside_addressbookmodal_adj">
            <PersonalAddContact
                @closeModal="closeAddAddress"
                v-if="showAddNew"
                :edit="is_edit"
                :editData="edit_data"
            />
        </div>
        <div class="address_book_header_wrapper">
            <div class="text_box_container">
                <input
                    type="text"
                    v-model="search.name"
                    placeholder="Search Name"
                />
            </div>
            <div class="text_box_container">
                <input
                    type="text"
                    v-model="search.address"
                    placeholder="Search Address"
                />
            </div>

            <a class="add_new" @click="filteredAddress">Search</a>
            <!--  <a
					class="add_new"
					v-if="auth().user_type == 'hub_admin' || auth().user_type == 'super_admin'"
					@click="showAddNew = true"
				>Add New</a>-->
        </div>
        <div class="address_book_body_wrapper">
            <table>
                <tr>
                    <td>NAME</td>
                    <td>BRANCH/OFFICE</td>
                    <td>ADDRESS</td>
                    <td>EMAIL</td>
                    <td>ACTION</td>
                </tr>
                <tbody v-if="!loading">
                    <tr v-for="(address, i) in addresses" :key="i">
                        <td>{{ address.name }}</td>
                        <td>{{ address.branch_name || 'N/A' }}</td>
                        <td>
                            <span v-if="address.floor">{{ address.floor }}, </span>
                            <span v-if="address.desk">{{ address.desk }}, </span>
                            <span v-if="address.address_1">{{ address.address_1 }}, </span>
                            <span v-if="address.address_2">{{ address.address_2 }}, </span>
                            {{ address.city }}, 
                            {{ address.state }}, 
                            {{ address.country }}, 
                            {{ address.postal_code }}
                        </td>
                        <td>{{ address.email }}</td>
                        <td>
                            <label v-if="!hideCheckbox">
                                <input
                                    type="checkbox"
                                    v-model="address.is_checked"
                                    @click="
                                        selectAddress(i, address.is_checked)
                                    "
                                />
                            </label>
                            <a
                                class="edit_icon"
                                @click="editAddress(address)"
                                v-show="
                                    auth().user_type == 'hub_admin' ||
                                        auth().user_type == 'hub_user' ||
                                        auth().user_type == 'user'
                                "
                            ></a>
                            <a
                                class="delete_icon"
                                @click="deleteAddress(address.id)"
                                v-show="
                                    auth().user_type == 'hub_admin' ||
                                        auth().user_type == 'hub_user' ||
                                        auth().user_type == 'user'
                                "
                            ></a>
                        </td>
                    </tr>
                </tbody>
            </table>
            <h4 v-if="!loading && addresses.length == 0">No data found</h4>
            <loader size="50" v-if="loading" />
        </div>
        <section class="centered addressbook_modal_footer">
            <a class="link_bt bt_cancel" @click="closeModal">Cancel</a>
            <a
                class="link_bt bt_save"
                @click="selectedAddress(addresses)"
                v-if="!hideCheckbox"
                >Select</a
            >
        </section>
    </div>
</template>
<script>
import PersonalAddContact from "./PersonalAddContact.vue";
export default {
    name: "PersonalAddressBookModal",
    components: {
        PersonalAddContact,
    },
    data() {
        return {
            showAddNew: false,
            addresses: [],
            address_id: "",
            filter: {
                address_type: {
                    myunit: true,
                },
            },
            searchname: "",
            searchaddress: "",
            search: {
                name: "",
                address: "",
                type: "personal",
            },
            is_edit: false,
            edit_data: {},
            loading: true,
        };
    },
    created() {
        if (this.category) {
            this.filter.address_type[this.category] = true;
        }
        this.fetchAddress();
    },
    /*  computed: {
    filteredAddress() {
      return this.addresses.filter(address => {
        return address.name.toLowerCase().includes(this.search.toLowerCase());
      });
    }
  },*/
    methods: {
        closeAddAddress() {
            this.showAddNew = false;
            this.is_edit = false;
            this.edit_data = {};
            this.fetchAddress();
        },
        filteredAddress() {
            console.log(this.search);
            this.axios
                .post("/api/address/all", this.search)
                .then((response) => {
                    console.log(response);
                    this.addresses = response.data.msg;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        selectAddress(index, isChecked) {
            if (this.type != "delivery" || this.callfrom == "single") {
                this.addresses = this.addresses.map((element) => {
                    element.is_checked = false;
                    return element;
                });
                this.addresses[index].is_checked = isChecked;

                let countries = localStorage.getItem('countries');
                countries = JSON.parse(countries);

                let selectedCountry = countries.find((country) => {
                    return country.name == this.addresses[index].country;
                });
                this.addresses[index].country_code = selectedCountry.code;
                this.addresses[index].from_address_book = true;
            }
        },
        editAddress(address) {
            this.is_edit = true;
            this.edit_data = address;
            this.showAddNew = true;
        },
        closeModal() {
            this.$emit("closeModal", {
                type: this.type,
            });
        },
        async fetchAddress() {
            this.loading = true;
            this.addresses = [];
            try {
                const response = await this.axios.post("api/address/personal/list");
                this.addresses = response.data.msg.map((element) => {
                    element.is_checked = false;
                    console.log(this.selectedAddresses);
                    if (
                        this.selectedAddresses.length > 0 &&
                        this.selectedAddresses.find(
                            (address) =>
                                address &&
                                (address.address_book_id == element.id ||
                                    address.id == element.id)
                        )
                    ) {
                        element.is_checked = true;
                    }
                    return element;
                });
            } catch (e) {
                console.log(e);
            }
            this.loading = false;
        },
        selectedAddress() {
            if (this.callfrom == "batch") {
                let addresses = this.addresses.filter(
                    (element) => element.is_checked == true
                );
                if (addresses.length > 0) {
                    this.$emit("selectedAddress", addresses);
                    this.closeModal();
                } else {
                    this.toast.error("Atleast one address must be selected");
                }
            } else {
                let address = this.addresses.find(
                    (element) => element.is_checked == true
                );
                if (address) {
                    this.$emit("selectedAddress", address);
                    this.closeModal();
                } else {
                    this.toast.error("Please select an address");
                }
            }
        },
        deleteAddress(id) {
            if (confirm("Are you sure you want to delete this address?")) {
                this.axios
                    .delete("/api/address/" + id)
                    .then((response) => {
                        this.toast.success(response.data.msg);
                        this.fetchAddress();
                    })
                    .catch((error) => {
                        console.log("error", error.response);
                    });
            }
        },
    },
    props: {
        type: String,
        category: String,
        callfrom: String,
        hideCheckbox: {
            default: false,
            type: Boolean,
        },
        selectedAddresses: {
            default: () => [],
            type: Array,
        },
    },
};
</script>
<style scoped>
.address_book_body_wrapper table {
    width: 100%;
}
.for_inside_addressbookmodal_adj {
    position: relative;
    height: 1px; /*udaipp*/
}
.for_inside_addressbookmodal_adj .add_contact_adj {
    position: absolute;
    z-index: 99;
}
input[type="checkbox"] {
    width: 18px;
    height: 18px;
    display: inline-block;
    border: 1px solid #333;
}
.address_book_body_wrapper table tbody tr:first-child {
    font-weight: 400;
}
</style>
<style lang="scss">
.popup_sub_container {
    padding: 20px;
    width: 100%;
    margin: auto;
    background: #fff;
}
.popup_sub_container > section {
    padding: 20px 0 0 0;
}
section.centered.addressbook_modal_footer {
    background: #f5f5f5;
    padding: 10px 0;
}
.address_book_header_wrapper {
    background: #f5f5f5;
    padding: 20px;
    height: 90px;
    display: flex;
    justify-content: space-between;
    .text_box_container {
        width: 42% !important;
        height: 50px;
        input[type="text"] {
            width: 100%;
            height: 50px;
            background: #fff;
            border: 1px solid #ddd;
            padding: 0 20px;
        }
    }
    .add_new {
        background: #06a5ed;
        height: 50px;
        line-height: 50px;
        width: 10%;
        text-align: center;
        display: block;
        border: 1px solid #ddd;
    }
}

.address_book_body_wrapper {
    padding-top: 50px;
    height: calc(100vh - 293px);
    overflow: auto;
    border-bottom: 5px solid #ddd;
    table {
        border-collapse: collapse;
        font-size: 14px;
        tr {
            &:first-child {
                font-weight: bold;
                font-size: 16px;
            }
        }
        td {
            border-bottom: 1px solid #ddd;
            padding: 20px 5px;
            &:first-child {
                min-width: 200px;
            }
            &:last-child {
                min-width: 150px;
            }
            label {
                display: inline-block;
                margin-right: 5px;
            }
            .edit_icon {
                width: 20px;
                height: 20px;
                display: inline-block;
                background: url(../../assets/ico_edit.png) no-repeat;
                background-size: cover;
                margin-right: 5px;
            }
            .delete_icon {
                width: 20px;
                height: 20px;
                display: inline-block;
                background: url(../../assets/ico_delete.png) no-repeat;
                background-size: cover;
                margin-right: 5px;
            }
        }
    }
}

a.link_bt {
    display: inline-block;
    width: 200px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    background: #333;
    color: #ccc;
    margin: 0 20px;
    font-weight: 600;
    text-transform: uppercase;
}
a.link_bt.bt_cancel {
    background: #000;
    color: #fff;
}
a.link_bt.bt_save {
    background: #004a7c;
    color: #fff;
}

i.closeme {
    width: 30px;
    height: 30px;
    background: #000;
    color: #06a5ed;
    font-size: 14px;
    text-align: center;
    line-height: 30px;
    border-radius: 100px;
    display: block;
    font-style: normal;
    font-weight: bold;
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
}
</style>
